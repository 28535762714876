import './App.css';
import useSound from "use-sound";
import beep from './beep.mp3';
import logo from './logo.png'
import overlord from './overlord.svg'
import React, { useState } from 'react';
import neo from './logos/neo.svg'
import flm from './logos/flm.svg'
import poly from './logos/poly.svg'
import dogesr from './logos/dogesr.svg'
import gleeder from './logos/gleeder.svg'
import ftw from './logos/ftw.svg';
import bird from './logos/bird.png';
import rent from './logos/rent.png';
import cancel from './cancel.svg';
import Modal from 'react-modal';
import persona from './11.svg'
import som from './logos/som.jpg'
import openland from './logos/openland.svg'

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border:'0px',
    marginRight: '-50%',
    backgroundColor: 'black',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 0px 100px rgba(0, 143, 223, 0.25)',
    borderRadius: '10px',
  },
};

function App() {
  const [playSound] = useSound(beep)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalCategory, setModalCategory] = useState(0);
  function openModal(category) {
    setIsOpen(true);
    setModalCategory(category)
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    
      <div className='app'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='modal-wrapper'>
          <div className='close-row'>
            <div className='left-point circle'>
            </div>
            <div className='btn-wrapper'>
              <span onClick={() => { playSound(); closeModal();}}><img src={cancel}></img></span>
            </div>
          </div>
          <div className='title-row'>
          {modalCategory==1?<span>About Us:</span>:<span></span>}
          {modalCategory==2?<span></span>:<span></span>}
          {modalCategory==3?<span>philosophy:</span>:<span></span>}
          {modalCategory==4?<span>Send email to us:</span>:<span></span>}
          </div>
          <div className='content-row'>
          {modalCategory==1?<span className='content-word'>
            Overlord Security is a web3 security company with a decentrlized organizational structure. We provide several services including smart contract auditing 
            (Neo and Ethereum), Code analysis, Formal verificaitons of code, blockchain software hardening.

          </span>:<span></span>}
          {modalCategory==3?<span className='content-word'>Justice and Fairness.</span>:<span></span>}
          {modalCategory==4?<span className={modalIsOpen?'typewriter add-animation':'typewriter'}>contact@overlord.wtf</span>:<span></span>}
          {modalCategory==2?
          <div className='people-logo-wrapper'>
            <div className='people-logo'>
              <img  className='people-logo-icon' src={persona}></img>
            </div>
            <div className='team-content'>
              <div className='team-title title-row'>
                Overlord Cyberpunk
              </div>
              <div className='team-job content-word'>
                  Overlord consists of a group of anonymous developers and researchers who are experts in the cybersecurity and blockchain.
              </div>
            </div>
          </div>:<span></span>}
          </div>
          <div className='footer-row'>
            <div className='circle'>
            </div>
            <div className='circle'>
            </div>
          </div>
        </div>
      </Modal>
        <div className='container'>
          <div className='header'>
            <div className='header-text' onClick={() => { playSound(); openModal(1);}}>
              About Us
            </div>
            <div className='header-text' onClick={() => { playSound(); openModal(2);}}>
              Team
            </div>
            <div className='header-logo'>
            <img className="token-icon" alt="icon" src={logo}/>
            </div>
            <div className='header-text' onClick={() => { playSound(); openModal(3);}}>
            philosophy
            </div>
            <div className='header-text' onClick={() => { playSound(); openModal(4);}}>
              Contact
            </div>
          </div>
          <div className='content'>
            <div className='content-d1'>
              <span className='hacking'>h</span>
              <span className='hacking'>a</span>
              <span className='hacking'>c</span>
              <span className='hacking'>k</span>
              <span className='hacking'>i</span>
              <span className='hacking'>n</span>
              <span className='hacking'>g</span>
            </div>
            <div className='overlord-wrapper'>
              <img className="overlord-icon" alt="icon" src={overlord}/>
            </div>
            <div className='content-d2'>
              <span className='overlord-text'>OVERLORD</span> are people who believe code is law <br></br>
              <div className='padding-wrapper'>and are guardians of the web3.0 security</div>
            </div>
          </div>
        </div>
        <div className='contant-second'>
          <div className='clients'>
            <div className='clients-title'>
              <div>
                Our Clients
              </div>
            </div>
            <div className='client-logos'>
              <div className='logo-line'>
                <div className='logo-wrapper'>
                  <img className="project-logo" alt="icon" src={neo}/>
                </div>
                <div className='logo-wrapper'>
                  <img className="project-logo" alt="icon" src={flm}/>
                </div>
                <div className='logo-wrapper'>
                  <img className="project-logo" alt="icon" src={poly}/>
                </div>
              </div>
              <div className='logo-line'>
                <div className='logo-wrapper '>
                  <img className="project-logo rent-logo" alt="icon" src={openland}/>
                </div>
                <div className='logo-wrapper'>
                  <img className="project-logo" alt="icon" src={ftw}/>
                </div>
                <div className='logo-wrapper dogesr'>
                  <img className="project-logo" alt="icon" src={dogesr}/>
                </div>
              </div>
              <div className='logo-line'>
              <div className='logo-wrapper'>
                  <img className="project-logo bird-logo" alt="icon" src={bird}/>
                </div>
                <div className='logo-wrapper'>
                  <img className="project-logo rent-logo" alt="icon" src={rent}/>
                </div>
                <div className='logo-wrapper'>
                <img className="project-logo rent-logo" alt="icon" src={som}/>
                </div>
              </div>
              <div className='logo-line'>
                <div className='logo-wrapper'>
                <img className="project-logo" alt="icon" src={gleeder}/>
                </div>
              </div>
            </div>
          </div>
          <div className='footer'>
            <div className='footer-claim'>
              Copyright © 2022 Overlord. All rights reserved
            </div>
            <div  className='item-wrappaer'>
                <div className='header-text2' onClick={() => { playSound(); openModal(1);}}>
                  About Us
                </div>
                <div className='split'></div>
                <div className='header-text2' onClick={() => { playSound(); openModal(2);}}>
                  Team
                </div>
                <div className='split'></div>
                <div className='header-text2' onClick={() => { playSound(); openModal(3);}}>
                philosophy
                </div>
                <div className='split'></div>
                <div className='header-text2' onClick={() => { playSound(); openModal(4);}}>
                  Contact
                </div>
                <div className='split'></div>
                <div className='header-logo'>
                <img className="token-icon" alt="icon" src={logo}/>
                </div>
            </div>
          </div>
        </div>
      </div>

  );
}

export default App;
